import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import DynamicForm from "../../../components/shared/DynamicForm/DynamicForm";
import ErrorIcon from "../icons/error";

const StageTwo = ({ data, handleSubmit, error, firstName, email, locale }) => {
  const Title = styled("h1")`
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -1.04px;
      margin-bottom: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 32px;
        letter-spacing: -0.83px;
        line-height: 38px;
        margin-top: 50px;
      }
    `,
    SubTitle = styled("h4")`
      font-size: 24px;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-bottom: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        line-height: 24px;
      }
    `,
    ErrorBoxWrapper = styled("div")`
      padding: 40px;
      background-color: ${theme.colors.ltGray};
      display: flex;
    `,
    ErrorBoxPTag = styled("p")`
      font-weight: bold;
      font-family: ${theme.fontFaces.help};
      font-size: 17px;
      letter-spacing: -0.37px;
      line-height: 24px;
      margin-left: 16px;
    `,
    ErrorBox = ({ message }) => (
      <ErrorBoxWrapper>
        <ErrorIcon width="19" height="19" />
        <ErrorBoxPTag>{message}</ErrorBoxPTag>
      </ErrorBoxWrapper>
    );
  return (
    <>
      <Title>{data.title}</Title>
      <SubTitle>{data.subTitle}</SubTitle>
      {error === "Email not found" && <ErrorBox message={data.errorMessage} />}
      <DynamicForm
        formValues={[
          {
            name: "email",
            label: data.inputBox,
            styles: {
              labelStyles: css`
                font-weight: bold;
                background-color: ${theme.colors.background};
                font-family: ${theme.fontFaces.inputPlaceholders};
                position: relative;
                top: -0.75em;
                padding: 0 8px;
                left: 4px;
              `,
              valueStyles: css`
                font-size: 18px;
              `,
              wrapperStyles: css`
                border-color: ${theme.colors.accent};
                border-width: 2px;
                border-style: solid;
                margin: 15px 0;
                display: flex;
                width: 50%;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px 0px 16px 12px;
                @media only screen and (max-width: ${theme.breakpoints
                    .helper1100}) {
                  width: 100%;
                }
              `,
              labelClassNames: "primary",
            },
            value: "",
            inputType: "text",
            required: false,
            handleValidation: (event, setError) => {
              const emailRegex = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
              if (!emailRegex.test(event.target.value)) {
                setError("You must enter a valid email address.");
              } else {
                setError(null);
              }
            },
            error: error === "Email not found" ? "" : error,
          },
        ]}
        cancelButton={{
          buttonText: data && data?.cta?.length && data?.cta[1]?.linkText,
          styles: {
            buttonStyles: css`
              width: 15%;
              margin-top: 8px;
              @media only screen and (max-width: ${theme.breakpoints
                  .helper1100}) {
                width: 45%;
                margin-bottom: 50px;
              }
            `,
            buttonTextStyles: css`
            `,
          },
          cancelButtonClasses: `black-button`,
        }}
        confirmButton={{
          buttonText: data && data?.cta?.length && data.cta[0].linkText,
          styles: {
            buttonStyle: css`
              width: 15%;
              @media only screen and (max-width: ${theme.breakpoints
                  .helper1100}) {
                width: 45%;
              }
            `,
            buttonTextStyles: css`
            `,
          },
          confirmButtonClasses: `black-button`,
        }}
        submit={handleSubmit}
        cancel={e => {
          e.preventDefault();
          navigate(`/onboardingSurvey/${locale === "es" ? "es" : ""}`, {
            state: {
              account: {
                email: email,
                first_name: firstName,
              },
            },
          });
        }}
        formStyles={css`
          grid-area: 2 / 4 / span 1 / span 1;
        `}
      />
    </>
  );
};

StageTwo.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  firstName: PropTypes.string,
  email: PropTypes.string,
};

export default StageTwo;
