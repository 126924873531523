import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../utils/global-styles/theme";

const ErrorIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width || "20px"} height={height || "20px"} viewBox="0 0 20 20">
      <g
        id="Account-Flows---Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Reconciliation-03"
          transform="translate(-354.000000, -405.000000)"
          fill={fillColor || theme.colors.dkOrange}
          fillRule="nonzero"
        >
          <g
            id="round-error-24px"
            transform="translate(354.000000, 405.000000)"
          >
            <path
              d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,11 C9.45,11 9,10.55 9,10 L9,6 C9,5.45 9.45,5 10,5 C10.55,5 11,5.45 11,6 L11,10 C11,10.55 10.55,11 10,11 Z M11,15 L9,15 L9,13 L11,13 L11,15 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

ErrorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
};

export default ErrorIcon;
