import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { navigate } from "gatsby";
import PrimaryButton from "../../../components/shared/PrimaryButton/PrimaryButton";
import useReconcileAndMigrateData from "../../../hooks/reconcile-and-migrate-data";

import { isLoggedIn } from "../../../utils/services/methods";

const MigrateFailure = ({ firstName, email, data, locale }) => {
  const Title = styled("h1")`
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.04px;
      line-height: 48px;
      margin-bottom: 20px;
    `,
    SubTitle = styled("h4")`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-bottom: 50px;
    `,
    PrimaryButtonStyles = css`
      padding: 10px 50px;
    `;

  return (
    <div>
      <Title>{data.title}</Title>
      <SubTitle>{data.subTitle}</SubTitle>
      <PrimaryButton
        text={data.cta[0].linkText}
        handleClick={() => {
          if (isLoggedIn()) {
            navigate(`/dashboard/${locale === "es" ? "es" : ""}`);
          } else if (email && firstName) {
            navigate(`/onboardingSurvey/${locale === "es" ? "es" : ""}`, {
              state: {
                account: {
                  email: email,
                  first_name: firstName,
                },
              },
            });
          } else {
            navigate(`/register/${locale === "es" ? "es" : ""}`);
          }
        }}
        styles={PrimaryButtonStyles}
      />
    </div>
  );
};

MigrateFailure.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
};

export default MigrateFailure;
