import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import { theme } from "../utils/global-styles/theme";
import {
  updateUserData,
  isLoggedIn,
  getUserDetails,
} from "../utils/services/methods";
import Layout from "../components/Layout/Layout";
import StageOne from "../components/Reconcile/child-components/StageOne";
import StageTwo from "../components/Reconcile/child-components/StageTwo";
import ConfirmCode from "../components/Registration/child-components/ConfirmCodeForm";
import MigrateFailure from "../components/Reconcile/child-components/MigrateFailure";
import { _post, _put } from "../utils/services/api.service";

function Reconcile({
  location: { search, state },
  data,
  pageContext: { locale },
}) {
  if (state) delete state.key;
  const reconcileStage1Data = data?.reconcileData?.nodes[0]?.contentModules[1],
    reconcileStage2Data = data?.reconcileData?.nodes[0]?.contentModules[2],
    [selectedOption, setSelectedOption] = useState("Yes"),
    [error, setError] = useState(""),
    [loading, setLoading] = useState(false),
    [apiEndpoint, setApiEndpoint] = useState("accounts/send-emailtoken"),
    [isReconcilingFromSignup, setIsReconcilingFromSignup] = useState(false),
    [apiRequestData, setApiRequestData] = useState(
      state
        ? {
            ...state.data,
          }
        : {}
    ),
    [accountInformation, setAccountInformation] = useState(
      state
        ? {
            ...state.account,
          }
        : {}
    ),
    handleSubmit = () => {
      selectedOption === "No" || selectedOption === "I don't remember"
        ? navigate(`/onboardingSurvey/${locale === "es" ? "es" : ""}`, {
            state: {
              account: {
                email: accountInformation.email,
                first_name: accountInformation.first_name,
                locale: locale
              },
            },
          })
        : navigate(`/reconcile${locale === "es" ? "/es" : ""}?stage=2`);
    },
    determineErrorResponse = errorObject => {
      errorObject.errors.map(error => {
        return setError(error.message);
      });
      setLoading(false);
    },
    determineDestination = response => {
      setLoading(false);
      switch (response.data.destination) {
        case "Reconciliation":
          return navigate(`/reconcile${locale === "es" ? "/es" : ""}?stage=3`);
        case "Migration":
          return navigate("/migrate/", {
            state: { user: response.data.user },
          });
        case "Dashboard":
          //extract user data from response and update in cookies

          updateUserData(response.data.user);
          if (isReconcilingFromSignup) {
            return navigate(
              `/registrationSuccess/${locale === "es" ? "es" : ""}`
            );
          } else {
            return navigate(`/dashboard/${locale === "es" ? "es" : ""}`);
          }

        default:
          return navigate(`/dashboard/${locale === "es" ? "es" : ""}`);
      }
    },
    ReconcileStageWrapper = styled("div")`
      width: 50%;
      margin: 60px auto 120px auto;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin: 0px 10px;
        width: auto;
      }
    `,
    ConfirmCodeWrapperStyles = `
     grid-area: 2 / 2 / span 1 / span 3;`,
    isFirstRender = useRef(true),
    urlParams = search.split("?")[1],
    userDetails = getUserDetails();

  /**
   * Use the useEffect hook to keep track of apiRequestData(which is the form
   * values that we must track between the reconcile stages) and make the
   * `/reconciles` request -RJH 12/2019
   */
  useEffect(() => {
    // If this is the first render, don't run the API call -RJH 12/2019
    if (urlParams === "stage=1") {
      setIsReconcilingFromSignup(true);
    }

    if (isFirstRender.current) {
      if (
        !accountInformation ||
        (!accountInformation.token &&
          urlParams !== "stage=failure" &&
          !accountInformation.email &&
          urlParams !== "stage=2")
      )
        return navigate(`/register/${locale === "es" ? "es" : ""}`);
      isFirstRender.current = false;
      return;
    }
    const callReconcileMerge = async () => {
      setLoading(true);
      setError(null);

      const response =
        apiEndpoint === "accounts/send-emailtoken"
          ? await _post({
              endpoint: "accounts/send-emailtoken",
              isPublic: true,
              args: {
                email: apiRequestData.email,
                first_name: accountInformation.first_name,
                locale,
              },
              locale,
            })
          : await _put({
              endpoint: "reconciles",
              isPublic: true,
              args: {
                master_email: accountInformation.email || userDetails.email,
                merge_email: apiRequestData.email,
                token: apiRequestData.token,
                locale,
              },
              locale,
            });
      if (!response.success) {
        determineErrorResponse(response);
      } else {
        if (
          response.data.message?.includes("Enter the confirmation code sent to")
        ) {
          setLoading(false);
          return navigate(`/reconcile${locale === "es" ? "/es": ""}?stage=3`);
        }
        determineDestination(response);
      }
    };
    callReconcileMerge();
  }, [apiRequestData]);

  return (
    <Layout loading={loading} locale={locale}>
      <ReconcileStageWrapper>
        {urlParams === "stage=1" && (
          <StageOne
            data={reconcileStage1Data}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleSubmit={handleSubmit}
            firstName={accountInformation.first_name}
            email={accountInformation.email}
            locale={locale}
          />
        )}
        {urlParams === "stage=2" && (
          <StageTwo
            data={reconcileStage2Data}
            handleSubmit={(e, value) => {
              setApiRequestData({
                ...apiRequestData,
                ...value,
                locale
              });
              setApiEndpoint("accounts/send-emailtoken");
            }}
            firstName={accountInformation.first_name}
            email={accountInformation.email}
            error={error}
            locale={locale}
          />
        )}
        {urlParams === "stage=3" && (
          <ConfirmCode
            handleSubmit={(e, value) => {
              setApiRequestData({
                ...apiRequestData,
                locale,
                token: value.token,
              });
              setApiEndpoint("reconciles");
            }}
            wrapperStyles={ConfirmCodeWrapperStyles}
            error={error}
            email={accountInformation ? accountInformation.email : ""}
            firstName={accountInformation ? accountInformation.first_name : ""}
            confirmCodeCopy={data?.confirmCode?.nodes[0]}
          />
        )}
        {urlParams === "stage=failure" && (
          <MigrateFailure
            firstName={accountInformation.first_name}
            email={accountInformation.email}
            data={data?.reconcileData?.nodes[0]?.contentModules[4]}
            locale={locale}
          />
        )}
      </ReconcileStageWrapper>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String!) {
    reconcileData: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Reconciliation & Migration Flow" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        node_locale
        contentModules {
          ... on ContentfulInformationSection {
            id
            informationSection {
              informationSection
            }
          }
          ... on ContentfulMigrationAndReconciliation {
            name
            title
            subTitle
            options
            finePrint {
              finePrint
            }
            cta {
              linkText
            }
            errorMessage
            inputBox
          }
        }
      }
    }
    confirmCode: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Reconciliation & Migration Flow" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        node_locale
        contentModules {
          ... on ContentfulInformationSection {
            id
            informationSection {
              informationSection
            }
          }
          ... on ContentfulMigrationAndReconciliation {
            name
            title
            subTitle
            options
            finePrint {
              finePrint
            }
            cta {
              linkText
            }
            errorMessage
            inputBox
          }
        }
      }
    }
  }
`;

Reconcile.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      account: PropTypes.objectOf(PropTypes.string),
      data: PropTypes.objectOf(PropTypes.string),
    }),
  }),
};

export default Reconcile;
